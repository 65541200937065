import React from 'react';
import './App.css';
import { Text, AppShell, Burger, Flex, Title, Center, Stack } from '@mantine/core';
import { HeroImageRight } from './components/HeroHeaderRight';
import { useDisclosure } from '@mantine/hooks';
import { EBLogo } from './components/EBLogo';
import { Navbar } from './components/Navbar';
import { ProjectCards } from './ProjectCards';
import { WorkCards } from './WorkCards';


function App() {
  const [opened, { toggle }] = useDisclosure();

  return (
    <AppShell
      header={{ height: 54 }}
      navbar={{
        width: 300,
        breakpoint: 'sm',
        collapsed: { mobile: !opened, desktop: !opened},
      }}
      >
      <AppShell.Header>
        <Flex
          mih={50}
          bg="rgba(0, 0, 0, .3)"
          gap="lg"
          justify="flex-start"
          align="center"
          direction="row"
          wrap="wrap-reverse"
        >
          <Burger
            opened={opened}
            onClick={toggle}
            size="md"
            style={{ paddingLeft: '20px' }}
          />
           <Text
            component='h1'
            variant="gradient"
            gradient={{ from: 'violet', to: 'red', deg: 131 }}
            style={{fontWeight: "bold", fontSize: "2.2em"}}
          >
            Elias Belzberg
          </Text>
        </Flex>
      </AppShell.Header>
      <Navbar />
      <AppShell.Main>
        <HeroImageRight />
        <Stack p="md">
          <Center pt="xl" pb="md">
            <Title>About Me 👋</Title>
          </Center>
          <Center>
            <Text maw={800} ta="center">
              I'm a software engineer that likes to make things and has a passion for
              technology and innovation. I am currently a student at the University
              of Washington studying Computer Science. I enjoy working on projects and surrounding myself like minded individuals.
            </Text>
          </Center>
          <Center pt="xl" pb="md">
            <Title>Recent Projects</Title>
          </Center>
          <ProjectCards />
          <Center pt="xl" pb="md">
            <Title>Where I've Worked</Title>
          </Center>
          <WorkCards />
          {/* <Center pt="xl" pb="md">
            <Title>Classes I've Taken</Title>

          </Center>
          <Center pt="xl" pb="md">
            <Title>Areas of Interest</Title>

          </Center>
          <Center pt="xl" pb="md">
            <Title>Fun stuff</Title>

          </Center> */}
        </Stack>
      </AppShell.Main>
    </AppShell>
  );
}

export default App;
