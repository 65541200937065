import { Group} from '@mantine/core';
import { ArticleCard } from './components/ArticleCard';

export function WorkCards() {
  return (
    <Group mx="auto" justify='center' gap="md">
    {/* MAYBE ADDING DATES HERE WOULD BE NICE */}
    <ArticleCard
      imageUrl="./img/direqt.png"
      articleUrl="https://www.direqt.ai/"
      title="Direqt"
      description=""
      maw={300}
      h={280}
      footer={"Full Stack Developer Intern"}
    />
    <ArticleCard
        imageUrl="./img/paulg.png"
        articleUrl="https://courses.cs.washington.edu/courses/cse154/"
        title="Paul G. Allen School of C.S.E."
        description=""
        maw={300}
        h={280}
        footer={"CSE 154 Teaching Assistant"}
        />
    <ArticleCard
      imageUrl="./img/evo.png"
      articleUrl="https://www.evo.com/"
      title="evo Seattle"
      description=""
      maw={300}
      h={280}
      footer={"Snowboard Sales Associate"}
      />
    <ArticleCard
      imageUrl="./img/slag.png"
      articleUrl="https://www.skatelikeagirl.com/"
      title="Skate Like a Girl"
      description=""
      maw={300}
      h={280}
      footer={"Volunteer and Skateboard Instructor"}
      />
  </Group>
  );
}