import { IconBookmark, IconHeart, IconShare } from '@tabler/icons-react';
import {
  Card,
  Image,
  Text,
  ActionIcon,
  Badge,
  Group,
  Center,
  Avatar,
  useMantineTheme,
  Stack,
} from '@mantine/core';
import classes from './css/ArticleCard.module.css';

interface ArticleCardProps {
  imageUrl: string;
  articleUrl: string;
  title: string;
  description: string;
  maw: number;
  h : number;
  footer : string
}

export function ArticleCard({ imageUrl, articleUrl, title, description, maw, h, footer }: ArticleCardProps) {
  const linkProps = { href: articleUrl, target: '_blank', rel: 'noopener noreferrer' };
  const theme = useMantineTheme();

  return (
    <Card
        radius={"md"}
        withBorder
        shadow="md"
        padding="lg"
        component="a"
        href={articleUrl}
        target="_blank"
        w={maw}
        h={h}>
      <Card.Section
        component='div'
        style={{borderColor: "solid black"}}
      >
          <Image src={imageUrl} height={180} />
      </Card.Section>

      <Text fw={500} component="a" {...linkProps} mt="md" >
        {title}
      </Text>

      <Stack align="flex-start" justify="space-between" h="100%" >
        <Text fz="sm" c="dimmed" pt={5}>
          {description}
        </Text>

        <Text fz="sm" inline>
          {footer}
        </Text>

      </Stack>
    </Card>
  );
}