import { Group } from '@mantine/core';
import { ArticleCard } from './components/ArticleCard';

export function ProjectCards() {
  return (
    <Group mx="auto" justify='center' gap="md">
    {/* MAYBE ADDING DATES HERE WOULD BE NICE */}
    <ArticleCard
      imageUrl="./img/cseed.png"
      articleUrl="https://cseed.co/"
      title="cseed"
      description="Inspiring tech entrepreneurship and startup culture at the University of Washington"
      maw={300}
      h={380}
      footer={"Founding Member & Advisor"}
    />
    <ArticleCard
        imageUrl="./img/cse154.png"
        articleUrl="https://docs.google.com/presentation/d/1i_x60a-ULrLPnljq-8_bXGSLZ05dCcE5kgJgnH4KQj0/edit?usp=sharing"
        title="CSE 154 Guest Lecture - UW"
        description="Guest lecture on extending CSE 154 (Web Programming) course topics to touch on Web Scraping, Data Collection, and Vector Databases"
        maw={300}
        h={380}
        footer={"Co-Lecturer"}
        />
    <ArticleCard
      imageUrl="./img/skigpt.png"
      articleUrl="https://ski-gpt.streamlit.app/"
      title="SkiGPT"
      description="With knowledge of over 380 skis and snowboards from evo.com, this custom ski sales chatbot will help find the perfect gear for you!"
      maw={300}
      h={380}
      footer={"Personal Project (check it out!)"}
      />
    <ArticleCard
      imageUrl="./img/autodubs.png"
      articleUrl="https://autodubbed.com/"
      title="AutoDubs"
      description="AI-based system that translates and dubs videos in multiple languages using the creator’s own voice. Dempsey Startup Competition, Foster School of Business, Top 36."
      maw={300}
      h={380}
      footer={"Project Lead"}
      />
    <ArticleCard
      imageUrl="./img/illustraitor.png"
      articleUrl="https://devpost.com/software/illustraitor"
      title="illustrAItor - DubHacks 22'"
      description="Making every book a picture book with AI generated images. Made during a 24 hour hackathon at the University of Washington"
      maw={300}
      h={380}
      footer={"Project Lead"}
    />
  </Group>
  );
}