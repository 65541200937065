import { AppShell, Stack } from "@mantine/core";
import { LinkIconBar } from "./LinkIcons";

export function Navbar() {
  return(
    <AppShell.Navbar p="md">
      <Stack align="center" justify="flex-start">
        <h1>Connect with me</h1>
        <LinkIconBar />
      </Stack>
    </AppShell.Navbar>
  );
}